@import "../../global/styles/global.scss";

#locations {
    min-height: 100vh;
}

#locations-hero {
    text-align: center;
    padding: 100px 25px 25px 25px;

    h2 {
        max-width: 1000px;
        margin: 0 auto;
    }
}

#locations-finder {
    text-align: center;

    form {
        text-align: center;
    }

    input {
        font-family: $body-font;
        border: none;
        border-bottom: 1px solid gray;
        display: block;
        margin: 25px auto;
        font-size: 2rem;
    }

    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b7b7b3;
      
      }
}

#locations-browser {
    min-height: 400px;

}

.half.locations-map {
    position: relative;
    min-height: 400px;
}


#locations-list {
    width: calc(100% - 50px);
    background-color: $green;
    color: white;
    padding: 150px 25px 150px 25px;
    padding-top: 150px;
    margin-top: -100px;
}


.location-info {
    max-width: 300px;
    margin: 25px auto;

    p {
        margin: 0;
    }

    a {
     
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
    }
}



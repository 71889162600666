.social-login-buttons {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    margin: 25px auto;

    a {
        text-decoration: none;
    }

    #facebook {
        button {
            background-color: #3b5998;
        }
    }

    #google {
        button {
            background-color: #4385F4;
        }
    }
}

.social-login {

    svg {
        font-size: 1.8rem;
    }

    a {
        text-decoration: none;
    }

    span:first-of-type {
        width: 40px;
    }

    span:last-of-type {
        width: calc(100% - 40px);
    }


    button {
        display: flex;
        justify-content: space-between;
        line-height: 25px;
        font-family: sans-serif;
        font-size: 1.4rem;
        width: 100%;
        text-transform: none;
        margin-bottom: 10px;
    }

  
}


body {
    font-family: $body-font;
    font-size: 1.6rem;
}

h1,h2,h3,h4,h5,h6 {
    font-family: $header-font;
    font-weight: 800;
}

p {
    font-family: $body-font;
    font-size: 1.8rem;
    line-height: 1.5;
}

h1 { 
    font-size: 4.8rem; 

    @include respond-below(sm) {
        font-size: 4rem;
    }
}
h2 { font-size: 3.6rem; }
h3 { font-size: 2.4rem; }
h4 { font-size: 2rem; }

.green-text {
    color: $med-green;
}

.white-text {
    color: white;
}

.orange-text {
    color: $orange;
}

@font-face {
    font-family: 'Gotham Black';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Gotham-Black.otf') format('otf')
}

@font-face {
    font-family: 'Gotham Book';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/GothamBook.ttf') format('truetype')
}

@font-face {
    font-family: 'Gotham Bold';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/GothamBold.ttf') format('truetype')
}

@font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style : normal;
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
  }

  .error-message {
      text-align: center;
      color: $dark-red;
  }
#schedule-step {

    p, h4 {
        max-width: 800px;
        margin: 15px auto;
    }

    p {
        text-align: center;
        font-size: 1.4rem;
    }

    @include respond-below(xs) {
        .react-datepicker-popper {
            left: -40px !important;
        }

        .delivery-fields-container, .pickup-fields-container {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .select, input {
        width: 200px;
        font-family: $body-font;
    }

    input::placeholder {
        font-size: 1.6rem;
        font-family: $body-font;
    }

}
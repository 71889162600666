form { 

    input[type="text"], input[type="submit"], input[type="number"] {
        -webkit-appearance: none;
    }

   

    .form-line {
        text-align: center;
        display: block;
        text-align: center;
        margin-bottom: 5px;
    }

    input, textarea {
        border: 1px solid $light-gray;
        border-radius: 2px;
        box-shadow: none;
        padding: 5px;
        font-family: $body-font;
        font-size: 1.4rem;
    }

    textarea {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
    }

    .form-error-message {
        font-size: 1.4rem;
        font-family: $body-font;
    }

    .action-button-container {
        width: 100%;
        text-align: center;

        button {
            font-size: 1.4rem;
        }

        .submit-button {
            background-color: $green;
            color: white;
            box-shadow: $medium-shadow;
            border-radius: 2000px;
            border: 3px solid white;
            padding: 8px 12px 8px 12px;
            text-transform: uppercase; 
            font-family: $accent-font !important;
            font-weight: normal;
            font-size: 1.4rem;
            letter-spacing: 1px;
            cursor: pointer; 
        }
    }
}

.form-group-label {
    padding: 10px;
    font-weight: bold;
    color: $green;
    border-bottom: 1px solid $green;

}

.account-form, .user-form, .location-form, .special-form, .app-orders-form, .settings-form {

    .submit-button {
        height: 40px !important;
    }
 

    label {
        margin-bottom: 5px;
    }

    input {
        font-size: 1.6rem !important;
        height: 30px !important;
    }

    .form-input {
        min-width: 150px;
        display: flex;
        flex-direction: column;
        margin: 10px;
    }

    .form-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .checkbox-input {
        display: flex;
        flex-direction: row;
        font-size: 1.6rem;

        input {
            margin-right: 10px;
        }
    }

    label {
        max-width: 500px;
        font-size: 1.2rem;
        text-transform: uppercase;
        color: $dark-green;
        text-align: left;
    }

    .current-value {
        font-size: 1.6rem;
    }
}



.react-datepicker {
    font-size: 1.6rem;
    font-family: $body-font;
}

.react-datepicker__current-month {
    font-size: 1.6rem;
}

.react-datepicker__day-name {
    margin: 10px;
}

.react-datepicker__day {
    margin: 10px;
}

.pickup-fields-container, .delivery-fields-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
}

.datepicker-field, .pickup-field, .delivery-field, .select-field {
    margin: 10px;
    label {
        display: block;
    }

    input {
        height: 25px;
        min-width: 150px;
    }
}


.form-input-info {
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: 5px;
    color: $dark-green;
}
@import "../../global/styles/global.scss";

#add-to-homescreen {
  position: "fixed";
  bottom: 0;
  border: 10px;
}

#home {

 
  margin-top: -200px; // To accommodate orange triangle

  h3 {
    font-weight: normal;
    font-family: $body-font;
    line-height: 1.5;
  }

  img.app-screen {
    max-width: 600px;
  }
}

#home-hero {
  background-image: -webkit-linear-gradient(-35deg, white 70%, $orange 30%);



  .half {
    padding-top: 200px; // To accommodate orange triangle
  }

  span {
    font-weight: bold;
  }

  .image-container {
    justify-content: flex-end;

    img {
      max-width: 700px;

     
    }
  }

  @include respond-below(md) {
    padding-top: 100px;
  }

  @include respond-below(sm) {
    background-image: -webkit-linear-gradient(-35deg, white 60%, $orange 40%);

    .half {
      padding-top: 125px;
    }

    .image-container {
      img {
        max-width: 300px;
        margin-bottom: 20px;
      }
    }
  }

  @include respond-below(xs) {
    padding-top: 250px;
    .half {
      padding-top: 0;
    }
  }
}

#home-special-bar {
  display: flex;
}

#home-promise {
  background-image: -webkit-linear-gradient(-25deg, $green 60%, white 40%);

  #promise-badge {
    top: 15%;
    left: 25%;
    position: absolute;
    width: 250px;
  }

  @include respond-below(sm) {
    h2 {
      margin: 0;
    }
  }
}

#home-info {
  @include respond-below(sm) {
    h2 {
      padding: 25px;
      text-align: left;
    }
  }
}

#home-icons {
  background-size: cover;
  background-position: center;
  background-color: $dark-green;

  @include respond-below(md) {
    padding-bottom: 100px;
  }

  @include respond-below(sm) {
    p {
      max-width: 400px;
    }
  }

  @include respond-below(xs) {
    background-position: 25%;

    .icon-grid {
      margin-top: 15px;
    }
  }
}

.bar {
  position: relative;
  background-color: black;
  height: 300px;

  h2 {
    margin: 0;
  }

  .bar-image {
    height: 100%;
    width: 60%;
    background-color: $med-green;
    // background-size: cover;
    // background-position: center;
  }

  .bar-color {
    height: 100%;
    width: 40%;
    background-color: $med-green;
  }

  @include respond-below(sm) {
    .bar-image {
      background-position: 65%;
    }
  }
}

#home-eco-shirt {
  position: absolute;
  right: 0;
}

.bounce {
  animation: bounce 2s;
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(16px);
  }
  60% {
    transform: translateY(12px);
  }
}

@import "../../global/styles/global.scss";


#contact {

    #triangle {
        max-height: 600px;

        @include respond-below(sm) {
           display: none;
         }
      
    }

    .contact-content {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
        padding: 25px;

        @include respond-below(sm) {
            margin-top: 50px;
        }
    }

    .contact-info-container {
       width: 60%;

       .contact-info {
           padding: 100px 25px 25px 25px;
           max-width: 800px;
       }
       
       h2 {
           text-align: left;
           padding: 0;
       }

       @include respond-below(sm) {
           width: 100%;

           .contact-info {
                padding: 0;
           }
        }
    }


    .image-container {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
        }

        @include respond-below(sm) {
            width: 100%;

            img {
                max-width: 250px;
            }
        }
    }

    .button-container {
        text-align: center;
        margin-top: 0;
        margin-bottom: 50px;
        
    }

    button {
        font-size: 1.4rem;
    }

    .green-banner {
        background-color: $green;
        height: 300px;
        margin-top: -100px;
        width: 100%;

        h2 {
            margin: 0;
            padding-top: 150px;
            color: white;

        }

        @include respond-below(xs) {
            h2 {
                font-size: 2.4rem;
            }
        }
    }
}
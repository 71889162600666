#app-settings {

    .cache-button {
        
    }

    .zip-codes {
        padding: 10px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        form {
            width: 200px;

            .zip {
                width: 100%;
                display: flex;
            }

            input {
                width: 100px;
            }
        }

    
        .zip {
            width: calc(50% - 100px);
            display: flex;
            margin-bottom: 10px;
        }

     
    }

    .form-group-label {
        cursor: pointer;
    }

    .form-action-message {
        text-align: center;
    }

    .submit-button {
        font-size: 1.2rem;
        background-color: $green;
        box-shadow: none;
        border: none;
    }

    .submit-button.add {
        font-size: 1.2rem;
        background-color: $orange;
        min-width: 100px;
        box-shadow: $small-shadow;
        border: 3px solid white;
    }



    .action-button-container {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .comforter-options {
        margin-left: 40px;

        p {
            margin: 0;
        }
    }

    .new-settings-item {
        font-weight: bold;
        padding: 25px;
        text-align: center;
        color: $med-green;
    }

    .expand-icon {
        cursor: pointer;
        margin-left: 15px;
    }

    .price-field {
        width: 80px;
    }

    .new-product {
        .form-checkbox-group {
            margin: -40px;
        }
    }


    .tailoring-product.new {
        margin-left: 40px;

        p {
            max-width: 200px;
        }
    }

    .product-pricing {
        p {
            margin: 0;
            width: 200px;
            display: flex;
            justify-content: space-between;

        }

        span {
            width: 30px;
        }

    }


    .product, .new-product, .zip, .new-zip {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        label {
            margin-right: 5px;
        }

        .tailoring-option {
           display: flex;


         p {
             width: 100px;
             margin: 0;
             line-height: 40px !important;
         }
        
        
        }

     

      

        .form-checkbox-group {
            width: 80%;
            display: flex;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .option-item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 30px;
        }

        input {
            margin-right: 20px;
            margin-bottom: 10px;
        }

        input[type="checkbox"] {
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .delete {
            background-color: $dark-red;
            height: 25px;
            width: 25px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top:  5px;
            margin-right: 15px;
            box-shadow: none;
            border: none;
        }

   
        
     
    }

    .new-product {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-left: 40px;

        input {
            margin-right: 20px;
        }

        .submit-button {
            font-size: 1.4rem;
            padding: 5px 10px 5px 10px;
        }

        .form-checkbox-group {
            width: 80%;
            display: flex;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    .product-name {
        width: 200px;
    }

    

    .note {
        max-width: 800px;
        margin: 15px;
        font-size: 1.6rem;
        font-weight: bold;

        a {
            color: $green;
            text-decoration: none;
        }
    }

    .action-button-container {
        margin-bottom: 50px;
    }

    .delete-message {
        margin-top: 10px;
        font-weight: bold;
        text-align: center;
    }

    .delete-account-button {
        background-color: $dark-red;
        font-size: 1.6rem;
        width: 250px;
        margin-top: 10px;
    }

    .disconnect-social {
        text-decoration: underline;
        cursor: pointer;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
        color: $dark-red;
    }

    .create-password-form {
        p {
            font-size: 1.6rem;
        }
    }

   
}

.settings-form {

    .select-field {
        margin: 0;
    }

    .css-g1d714-ValueContainer {
        margin-left: 5px;

    }

    .css-2b097c-container {
        width: 150px;
    }

    .form-input {
        min-width: 220px;
    }

 
 
   
}



.ordering-disabled-banner {
    color: $dark-red;
    text-align: center;
    font-weight: bold;
    max-width: 500px;
    margin: 25px auto;
}
.sticker {
    text-transform: uppercase;
    text-align: center;
    font-family: $header-font;
    background-color: $med-green;
    color: white;
    border-radius: 100%;
    width: 275px;
    height: 275px;
    box-shadow: $medium-shadow;
    position: absolute;
    top: -25px;
    right: 30%;
    border: 10px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    padding: 25px;

    span:nth-of-type(1) {
        color: #F5C732;
        font-family: $accent-font;
        font-weight: normal;
        font-size: 3rem;
    }

    span:nth-of-type(2) {
        font-size: 9rem;
    }

    span:nth-of-type(3) {
        font-size: 3.5rem;
    }

    @include respond-below(sm) {
        width: 150px;
        height: 150px;
        right: 10px;
        font-size: 1.8rem;

        span:nth-of-type(1) {
            color: #F5C732;
            font-family: $body-font;
            font-weight: normal;
            font-size: 2rem;
        }
    
        span:nth-of-type(2) {
            font-size: 5rem;
        }
    
        span:nth-of-type(3) {
            font-size: 2rem;
        }

    }
    
}
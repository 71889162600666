.modal {
    border-radius: 5px;
    position: absolute;
    min-height: 300px;
    max-width: 1000px;
    top: 0;
    width: 90%;
    margin-left: 5%;
    background-color: white;
    box-shadow: $small-shadow;
    z-index: 101;

    @include respond-below(sm) {
        min-height: 400px;
    }

    @include respond-below(xs) {
        margin-left: 0;
        width: 100%;
        
    }
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0,0.5);
    height: 100vh;
    width: 100%;
}

.modal-content {
    h2 {
        font-size: 3rem;
        padding: 5px;
        color: $med-green;
    }

    .action-button-container {
        flex-wrap: wrap;
    }

    button {
        font-size: 1.8rem;
        margin: 10px;
    }

    .confirm {
        background-color: $dark-red;
    }

    .cancel {
        background-color: #a9a9a9;
    }


}
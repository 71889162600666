.locations-container {
  max-width: 1000px;
  margin: 0 auto;
  column-count: 2;

  p {
    margin: 0;
  }
}

h2 {
  text-align: center;
  padding: 25px;
}

.locations-list-item {
  break-inside: avoid-column;
  margin-bottom: 22px;
  p {
    font-size: 1.6rem;
  }
  .address {
    font-weight: bold;
  }
}

@import "../../styles/global.scss";

#triangle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -100;
    height: 75%;

    img {
        height: 100%;
    }

    @include respond-below(xs) {
        display: none;
    }
}
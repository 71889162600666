.widget {
    background-color: $green;
    border-radius: 12px;
    width: 100%;
    color: white;
    padding: 10px;

    h3 {

        font-family: $header-font;
    }

    p {
        font-size: 2rem;
    }
}


@import "../../styles/global.scss";

#locations-images {
    .flex-container {
        justify-content: center;
        max-width: 1200px;
        margin: 0 auto;
    }
    .location-image {
        margin: 10px;
        width: 30%;
        height: 250px;
        background-position: cover;
        background-position: center;
    }

    @include respond-below(xs) {
        .location-image {
            margin: 0;
            height: 100px;
            width: 33.3%;
        }
    }
}
#login {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    min-height: 100vh;
    // margin-top: -100px;

    .message {
        margin-bottom: 25px;
        margin-top: 25px;
    }

    // .message {
    //     margin-top: 100px;
    //     margin-bottom: -25px;
    //     font-weight: bold;
    // }

    @include respond-below(sm) {
        margin-top: 0;
    }

    h2 {
        color: $green;
        text-align: center;
    }

    h3 {
        color: $med-green;
        text-align: center;
    }

    p {
        text-align: center;
    }

    input {
        width: 250px;
        height: 30px;
        font-size: 1.6rem;
        font-family: $body-font;
    }

    input:first-of-type {
        margin-bottom: 10px;
    }

    .action-button-container {
        margin-top: 15px;
    }

    input[type="submit"] {
        height: 40px;
    }

    .forgot-password-container, .signup-container {
        text-align: center;
        margin-top: 25px;
        a {
            color: black;
        }
    }

    

 
}

#login-form {
    margin-bottom: 100px;
}

#continue-as-guest {
    text-align: center;

    a {
        color: black;
    }
}
#app-order {
  .content {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.login-link {
  text-align: center;
  margin-bottom: 25px;

  a {
    font-weight: bold;
    color: $med-green;
  }
}

.order-step {
  .step-header {
    text-align: center;
    color: $med-green;
  }
}

#checkout-step.order-step {
  width: 80%;

  #order-form {
    margin: 0 auto;
    max-width: 500px;
  }
}

.progress-bar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 800px;
    margin: 0 auto;

    a { 
        text-decoration: none; 
        color: black;
    }
}

.progress-bar-step  {
    text-align: center;
    position: relative;

    .step-bar {
        z-index: 0;
        position: absolute;
        top: 30%;
        left: 50%;
        width: 100%;
        height: 5px;
        background-color: gray;

        @include respond-below(xs) {
            top: 20%;
        }
    }

    .step-number {
        cursor: pointer;
        position: relative;
        z-index: 1;
        background-color: gray;
        padding: 10px;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
        color: white;
        margin: 0 auto;
    }

    .step-label {
        text-align: center;
        margin-top: 5px;


        @include respond-below(xs) {
            font-size: 1.2rem;
        }
    }
}

.progress-bar-step.active {
    .step-bar, .step-number {
        background-color: $green;
    }
}
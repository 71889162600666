.dashboard-table {
    border-radius: 5px;
    max-width: 1200px;
    margin: 25px auto;
}

.dashboard-table-header {

    .dashboard-table-column {
        background-color: $green;
        color: white;
        font-weight: bold;
    }

    .dashboard-table-column:first-of-type {
        border-radius: 5px 0 0 0;
    }

    .dashboard-table-column:last-of-type {
        border-radius: 0 5px 0 0;
    }
}

.dashboard-table-column {
    padding: 5px 15px 5px 15px;
    background-color: white;
    min-width: 100px;
}

.dashboard-table-rows {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .row {
        display: grid;
        font-size: 1.4rem;
    }

    .row:last-of-type {
        .dashboard-table-column:first-of-type {
            border-radius: 0 0 0 5px;
        }
    
        .dashboard-table-column:last-of-type {
            border-radius: 0 0 5px 0;
        }
    }
}

.table-row-action {
    font-family: inherit;
    font-size: inherit;
    border: none;
    box-shadow: none;
    background-color: unset;
    color: inherit;
    text-decoration: underline;
    text-transform: unset;
}

.row.show-more-row {
    grid-template-columns: 1fr !important;
    border-bottom: 0 !important;
    background-color: transparent !important;

    button {
        max-width: 200px;
        margin: 10px auto;
        font-size: 1.2rem;
        background-color: $med-green;
    }
}
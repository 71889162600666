@import "../../global/styles/global.scss";

#promise {
    margin-top: -200px;
}

#promise-hero {
    background-image: -webkit-linear-gradient(-35deg, white 65%, $med-green 35%);

    h1, p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .half {
        padding-top: 200px;
    }

    @include respond-below(sm) {
        padding-top: 150px;
    }

    @include respond-below(xs) {
        padding-top: 75px;
    }
}

#promise-main {
    background-size: cover;

    .icon-list {
        padding: 25px;
    }

    .icon-list-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 50px;

        p {
            margin: 0;
        }

        p:first-of-type {
            color: $green;
            font-weight: bold;
        }

        .image-container {
            width: 100px;
            text-align: center;

            @include respond-below(xs) {
                width: 100%;
            }
        }

        .info {
            width: calc(100% - 125px);

            @include respond-below(xs) {
                width: 100%;
            }

            p {
                margin-bottom: 15px;
            }

            a {
                color: $green;
                text-decoration: none;
            }
        }

        img {
            height: 100px;
            width: auto;
        }
    }
}


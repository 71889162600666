@import "../../global/styles/global.scss";

#pricing {
    padding-top: 50px;
 
    @include respond-below(sm) {
        .widget {
            width: 75%;
            margin: 25px auto;
            min-width: 200px;
        }
    }

    .widget-container { 
        width: 100%;
        min-width: 300px;
    }

    .widget {
        width: 75%;
        margin: 25px auto;
    }
}

#pricing-hero h3 {
    font-weight: normal;
    font-family: $body-font;
    line-height: 1.5;
}

#pricing-hero {
    position: relative;
    margin-bottom: 50px;

    span {
        font-weight: bold;
    }

    .content-container {
        position: relative;
        z-index: 10;
        max-width: 550px;
        margin: 0 auto;
    }

    .sticker {
        position: absolute;
        top: 50%;
        left: 50%;
        right: unset;
        height: 200px;
        width: 200px;

        span:nth-of-type(1) {
            font-size: 2.4rem;
        }
    
        span:nth-of-type(2) {
            font-size: 6rem;
        }
    
        span:nth-of-type(3) {
            font-size: 3rem;
        }
    }


    @include respond-below(sm) {

        h3 {
            margin-bottom: 0;
        }

        .content-container {
            padding-top: 0;
            padding-bottom: 0;
        }

        .sticker {
            top: 80vh;
            bottom: 0;
            left: 15%;
            height: 150px;
            width: 150px;

            span:nth-of-type(1) {
                font-size: 2rem;
            }
        
            span:nth-of-type(2) {
                font-size: 5rem;
            }
        
            span:nth-of-type(3) {
                font-size: 2rem;
            }
        }

        .image-container {

            img {
                max-width: 300px;
                margin-bottom: 150px;
            }
        }
    }  

    @include respond-below(xs) {

        .sticker {
            top: 70vh;
        }

        .image-container {

            img {
                margin-bottom: 00px;
            }
        }
    } 
}

#wash-fold {

    @include respond-below(sm) {
        img {
            max-height: 300px;
            width: auto;
            margin: 0 auto;
            margin-top: 50px;
        }

        .widget-container {
            min-width: unset;
        }
    }
}

#dry-cleaning {
    background-color: #F2F2E2;
    flex-wrap: wrap-reverse;

    @include respond-below(sm) {
        .image-container img {
            max-height: 500px;
            width: auto;
            margin: 0 auto;
        }

   
        .widget-container {
            width: 100%;
            min-width: unset;
        }
    }
}

#pressed {
    padding-bottom: 200px;

    @include respond-below(sm) {
       .half {
           height: 500px;
       }
    }

    @include respond-below(xs) {
        padding-bottom: 0;
    }
   
}

#pressed .image-container {
    position: relative;

    .hanging-shirts {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 450px;

        .shirts {
            position: relative;
            z-index: 10;
        }

    
    }

    .pressed-shirts {
        position: absolute;
        z-index: 15;
        bottom: -150px;
        left: 25%;
        max-width: 520px;

    }

    @include respond-below(xs) {
        .pressed-shirts {
            bottom: -25px;
            width: 100%;
            left: 0;
        }

    }
}

#tailoring {
    background-color: #F2F2E2;

    .half img {
        max-width: 300px;
    }

}

#comforter {
    background-color: white;


}

#specials-section {
    background-color: #F2F2E2;
}

.calculator-form {
    .radio-group, .checkbox-group {
        display: flex;
        flex-wrap: wrap;
    }

    .checkbox-group {
        margin-top: 15px;
    }

    .radio-item, .checkbox-item {
        width: 50%;
        min-width: 100px;
        display: flex;
        font-size: 1.8rem;
        font-family: $body-font;
        margin-bottom: 10px;
    }
    input {
        font-size: 1.6rem;
        font-family: $body-font;
    }
    input[type="text"], input[type="number"] {
        background-color: white;
        padding: 5px;
        border-radius: 1000px;
        border: 2px solid white;
        margin: 5px;
    }

    input[type="number"] {
        text-align: center;
        font-size: 1.8rem;
        border: none;
    }
    input[type="submit"] {
        background-color: $orange;
        padding: 5px 10px 5px 10px;
        border-radius: 1000px;
        border: 2px solid white;
        font-family: $accent-font;
        text-transform: uppercase;
        color: white;
        letter-spacing: 1px; 
        margin: 5px;
    }

    select {
        border-radius: 1000px;
        border: none;
        width: 100%;
        font-size: 1.6rem;
        font-family: $body-font;
        background: transparent;
        border: 1px solid white;
        border-radius: 1000px;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        color: #3F3F3F;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
            appearance: none;
    }

    .select-wrapper {
        position: relative;	
        background: white;
        border-radius: 1000px;
        border: none;
        select {
            position: relative;
        }
    }

    .down-caret {
        position: absolute;
        top: 0; 
        right: 15px;
        width: 40px;
    }
    
    // .select-wrapper:after {
    //     font-family: FontAwesome;
    //       content: '\f0d7';
    //       font-size: 40px;
    //       position: absolute;
    //       top: 0px;
    //       right: 20px;
    //       color: $orange;
    //       pointer-events: none;
    // }
}

.calculator-results {
    padding-bottom: 15px;

    .calculator-total {
        display: flex;
        justify-content: space-between;

        padding-bottom: 10px;
        font-weight: bold;
        font-size: 2.4rem;
        padding-top: 10px;

        span {
            font-weight: normal;
            font-size: 1.4rem;
        }

        @include respond-below(md) {
            font-size: 1.8rem;
        }

    }

    .calculator-details {
        span { 
            font-weight: bold; 
            display: block; 
            margin-bottom: 5px;
        }
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .calculator-max {
        display: flex;
        justify-content: space-between;
      
        padding-top: 10px;
        font-size: 2.4rem;

        @include respond-below(md) {
            font-size: 1.8rem;
        }

        @include respond-below(xs) {
            font-size: 1.4rem;
        }
    }
}

#wash-fold-calculator {
    padding-bottom: 50px;
    position: relative;

    .calculator-results {
     
        margin-top: 10px;
        font-size: 3rem;
        text-align: center;

        @include respond-below(sm) {
            font-size: 2rem;
        }
    }
}

#tailor-calculator {
    position: relative;
    padding-bottom: 50px;
}

.widget-form-error {
    margin-top: 15px;
    color: #a02020;
    text-align: center;
    width: 100%;
}

#dry-cleaning-calculator {
    padding-bottom: 100px;
}

#comforter-price-list {

    h4 {
        font-family: $body-font;
        font-weight: normal;
    }
    ul {
        padding-left: 15px;
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    span {
        display: inline-block;
    }

    li::before {content: "•"; color: white; position: absolute; left: -10px;}

    li {
        width: 40%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 5px;
    }

}

.pricing-page-zip-form {
   
    p {
        font-size: 1.8rem !important;
    }
   
    input {
        color: white !important;
    
    }

    input::placeholder{ 
        color: white !important;
    }
}
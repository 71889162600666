#signup{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    padding-bottom: 100px;

    @include respond-below(sm) {
        margin-top: 0;
    }

    h2 {
        color: $green;
        text-align: center;
    }

    h3 {
        color: $med-green;
        text-align: center;
    }

    input {
        width: 250px;
        height: 30px;
        font-size: 1.6rem;
        font-family: $body-font;
    }

    input:first-of-type {
        margin-bottom: 10px;
    }

    .action-button-container {
        margin-top: 15px;
    }

    input[type="submit"] {
        height: 40px;
    }

    .login-container {
        text-align: center;
        margin-top: 25px;
        a {
            color: black;
        }
    }

 
}
.icon-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;


    .icon-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            margin: 0 auto;
            max-height: 80px;
        }

        span {
            margin-top: 10px;
            display: block;
            color: white;
            text-transform: uppercase;
            text-align: center;
            font-family: $accent-font;
            font-weight: normal;
            font-size: 1.4rem;
        }
    }

    @include respond-below(sm) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}
#address-step {
    flex-direction: column; 
    align-items: center;

    .form-line {
        max-width: 300px;
        margin: 0 auto;
    }

    label {
        text-align: left;
        display: block;
        margin-bottom: 5px;
        font-size: 1.4rem;
    }

    input {
        width: 100%;
        margin-bottom: 10px;

      @include respond-below(xs) {
          width: 95%;
      }
    }

    .customer-information {
        margin-top: 50px;
    }
}
button {
  background-color: $orange;
  -webkit-appearance: none;
}

button.bg-green {
  background-color: $green;
}

button,
.submit-button {
  color: white;
  box-shadow: $medium-shadow;
  border-radius: 2000px;
  border: 3px solid white;
  padding: 8px 12px 8px 12px;
  text-transform: uppercase;
  font-family: $accent-font;
  font-weight: normal;
  font-size: 2rem;
  letter-spacing: 1px;
  cursor: pointer;
  -webkit-appearance: none;

  @include respond-below(sm) {
    font-size: 1.8rem;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.option-button {
  @extend button;
  text-decoration: none;
  font-size: 1.4rem;
  margin: 10px;
}

.link-button {
  @extend button;
  text-decoration: none;
  font-size: 1.4rem;
  margin: 10px;
}

.button-container {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: right;
}

.action-button-container {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

button.disable {
  background-color: #e22626;
}

.cancel-button {
  background-color: #a9a9a9;
}

.action-links-container {
  padding: 10px;
  text-align: right;

  button {
    margin: 10px;
    background-color: $med-green;
    font-size: 1.4rem;
    padding: 5px 10px 5px 10px;
  }

  .delete-button {
    background-color: #e22626;
  }

  .edit-button {
    background-color: #a9a9a9;
  }
}

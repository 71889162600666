.dashboard-cards {

}

.dashboard-cards-section {

    .label {
        border-bottom: 2px solid $med-green;
        margin-bottom: 15px;
        margin-top: 15px;
        padding-bottom: 5px;
        color: $med-green;
        font-weight: bold;
        font-family: $header-font;
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
    }

    .card {
        border-radius: 5px;
        width: 350px;
        min-height: 125px;
        margin-right: 30px;
        margin-bottom: 30px;
        background-color: white;
        font-size: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond-below(xs) {
            width: 100%;
            margin-right: 0;
        }
    }

    .card-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        padding: 5px;

        p {
            font-size: inherit;
            margin: 0;
        }

        .thirty {
            width: 30%;
        }

        .seventy {
            width: 70%;
        }

        .half {
            width: 50%;
        }
    }

    .card.step-1 {
        .card-section:first-of-type > .card-row:first-of-type {
            border-top: 10px solid $yellow;
            // background-color: $yellow;
            // color: black;
        }
    }

    .card.step-2 {
        .card-section:first-of-type > .card-row:first-of-type {
            border-top: 10px solid $dark-green;
            // background-color: $dark-green;
            // color: white;
        }
    }

    .card.step-3 {
        .card-section:first-of-type > .card-row:first-of-type {
            border-top: 10px solid $med-green;
            // background-color: $med-green;
            // color: white;
        }
    }

    .card.step-4 {
        .card-section:first-of-type > .card-row:first-of-type {
            border-top: 10px solid $green;
            // background-color: $green;
            // color: white;
        }
    }

    // .card.active-order {
    //     .card-section:first-of-type > .card-row:first-of-type {
    
    //         border-radius: 10px 10px 0 0;
    //     }
    // }

    .card {
        .card-section:first-of-type > .card-row:first-of-type {
            // background-color: #d2d2d2;
            color: black;
            border-radius: 5px 5px 0 0;
        }
    }

   

}

#zip-form {
    display: flex;
    flex-wrap: wrap;
 
}

.zip-message {
    margin-top: 5px;
    margin-bottom: 5px;
}

.zip-input-container {
    width: 225px;
    margin-right: 25px;
    
    input {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $dark-gray;
        font-size: 2rem;
        font-weight: normal;
        padding-bottom: 5px;
        border-radius: 0;
        width: 100%;
        
    }

    input:focus {
        outline: none;
    }
}

.zip-form-button {
    

    input {
        background-color: $orange;
        font-size: 1.6rem;
        margin-bottom: 10px;
    }
}
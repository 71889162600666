#sidebar {
    position: relative; 
    width: 275px;
    padding: 10px;

    @include respond-below(xs) {
        padding: 0;
        width: 50px;
     }
}

#sidebar-menu {

    .icon {
        width: 30px;
        text-align: center;
    }

    .label {
        font-size: 1.6rem;
        margin-left: 15px;
    }

    a {
        display: flex;
        text-decoration: none;
        color: $med-green;
        font-family: $body-font;
        padding: 5px 15px 5px 15px;
        margin-bottom: 15px;
    }

    a.active {
        background-color: $green;
        color: white;
        border-radius: 1000px;
    }


    @include respond-below(xs) {
       margin: 5px 5px 5px 5px;

       .icon {
           width: 20px;
       }

        .label {
            display: none;
        }
    }
}

.welcome-message {
    margin-bottom: 25px;
    margin-left: 25px;
    font-size: 1.4rem;

    @include respond-below(xs) {
        display: none;
    }
}
#app-account {
    .social-login-buttons {
        max-width: 250px;
        display: flex;
        margin: 0 auto;
        justify-content: center;
    }

    .note {
        max-width: 800px;
        margin: 15px;
        font-size: 1.6rem;
        font-weight: bold;

        a {
            color: $green;
            text-decoration: none;
        }
    }

    .action-button-container {
        margin-bottom: 50px;
    }

    .delete-message {
        margin-top: 10px;
        font-weight: bold;
        text-align: center;
    }

    .delete-account-button {
        background-color: $dark-red;
        font-size: 1.6rem;
        width: 250px;
        margin-top: 10px;
    }

    .disconnect-social {
        text-decoration: underline;
        cursor: pointer;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
        color: $dark-red;
    }

    .create-password-form {
        p {
            font-size: 1.6rem;
        }
    }
}

.account-form {
    .css-2b097c-container {
        width: 100px;
    }

    .form-input {
        min-width: 220px;
    }
 
   
}

.dashboard-container {
  min-height: 70vh;
  margin-top: 50px;
  display: flex;
  justify-content: center;

  @include respond-below(xs) {
    min-height: 400px;
  }
}

.dashboard-table-column {
  display: flex;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }

  p {
    margin: 0;
    font-size: inherit;
  }

  p:first-of-type {
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.4rem;
  }

  a {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }

  svg {
    margin-bottom: -2px !important;
    margin-left: 5px;
    height: 100%;
    cursor: pointer;
  }
}

.dashboard-list-column {
  display: block;
}

.dashboard-panel-container {
  min-height: 70vh;
  position: relative;
  background-color: #f9f9f9;
  width: 80%;
  margin-right: 25px;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 75px;
  overflow-x: scroll;
  padding-bottom: 100px;

  @include respond-below(sm) {
    width: 100%;
    margin: 0;
  }

  @include respond-below(xs) {
    min-height: 400px;
  }

  .dashboard-table {
    min-width: 1250px;
    overflow: auto;
  }

  .dashboard-button-container {
    text-align: center;
  }

  .dashboard-view-style {
    position: absolute;
    top: 10px;
    right: 20px;
    text-align: right;

    .view-style-button {
      margin-left: 20px;
      display: inline-block;
      cursor: pointer;
      font-size: 2rem;

      svg {
        path {
          fill: gray;
        }
      }
    }

    .view-style-button.active {
      svg {
        path {
          fill: $green;
        }
      }
    }
  }

  .button-link {
    font-size: 1.6rem;
    padding: 5px 10px 5px 10px;
  }

  @include respond-below(xs) {
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 200px;
  }
}

.dashboard-table-column {
  background-color: white !important;
}

.dashboard-table-header .dashboard-table-column {
  background-color: $green !important;
}

.export-results-container {
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  button {
    background-color: #545650;
    font-size: 1.4rem;
    box-shadow: none;
  }
}

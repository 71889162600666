@import "../../global/styles/global.scss";

#support-hero {
    height: 70vh;
    min-height: 300px;

    img {
        height: 100%;
        width: auto;
    }

    .half {
        height: 100%;
    }

    @include respond-below(sm) {
        height: unset;
        margin-top: 25px;


        img {
            display: none;
        }
    }
}

#support-accordion {
    z-index: 100;
    margin-top: -75px;
    width: 65%;
    min-width: 500px;
    padding: 25px;

    @include respond-below(sm) {
        width: calc(100% - 50px);
        min-width: unset; 
    }

    .accordion-item {
        margin-bottom: 15px;

        .answer {
            position:relative;
            transition: all 0.3s ease;
            padding: 5px;
            opacity: 0;
            max-height: 0;
        }
    }

    .accordion-item.active {
        .answer {
            opacity: 100;
            max-height: 500px;
        }
    }

    .question {
        cursor: pointer;
        position: relative;
        padding: 10px 25px 10px 5px;
        background-color: $orange;
        color: white;

        svg {
            width: 25px;
            height: 25px;
            top: 7px;
            position: absolute;
            right: 25px;
        }
    }


}

#faq-section {
    min-height: 800px;
    background-position: bottom right;
    background-size: 100%;
    background-repeat: no-repeat;

    @include respond-below(sm) {
        min-height: unset;
        padding-bottom: 100px;
    }
}
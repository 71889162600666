@import "../../global/styles/global.scss";

#business-services {
    margin-top: -100px;

    #triangle {
        z-index: 0;
        height: 80vh;
    }
}

#business-services-hero {
    flex-wrap: wrap-reverse;
    z-index: 100;
    // background-image: -webkit-linear-gradient(-35deg, white 70%, #82B020 30%);

 

    .half {
        padding-top: 100px;
    }

    img {
        max-width: 600px;
        margin: 0 auto;
    }

    p {
        font-weight: bold;
        color: $med-green;
    }

    .content-container {
        margin-top: 125px;
        z-index: 50;
    }

    @include respond-below(sm) {
        padding-top: 150px;

        .content-container {
            margin-top: 0;
        }

        .half {
            padding-top: 25px;
        }

        img {
            max-width: 300px;
        }
    }
}

#business-services-main {
    background-color: white;

    .content {
       width: 50%;
    }

    .content-container {
        margin-top: 0;
    }

    @include respond-below(sm) {
        .content {
            width: 100%;
        }
    }
 

}

#business-services-illustration {
    background-size: cover;
    min-height: 60vh;
    margin-top: -100px;
}

.business-services-list {
    margin-left: 25px;

    li {
        margin-bottom: 15px;
    }
}
.user-form {
    max-width: 500px;
    margin: 0 auto;

    input {
        width: 100%;
    }

    input[type="submit"] {
        width: 100px;
    }
    
    .css-2b097c-container {
        width: 90%;
        text-align: left;
        margin-bottom: 10px;
    }
}


#user-table-header {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: $green;
    border-radius: 5px 5px 0 0;
}

#user-table-rows {
    .dashboard-table-column {
        background: none;
    }

    .row {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .row:nth-child(even) {
        background-color: #e4e4e4;
    }
}


#app-user {
    .form-input { 
        text-align: left;
    }

    .checkbox-input {

        input {
            width: 25px;
            height: 25px;
        }
        label {
            margin-top: 10px;
        }

    }
}
#location-table-header {
    grid-template-columns: 1fr 1fr 1fr;
    background-color: $green;
    border-radius: 5px 5px 0 0;
}

#location-table-rows {
  

    .row {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .row:nth-child(even) {
        background-color: #e4e4e4;
    }
}

.location-form {
    text-align: left;
}
#special-table-header {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    background-color: $green;
    border-radius: 5px 5px 0 0;
}

#special-table-rows {
    .dashboard-table-column {
        background: none;

        p { text-decoration: underline; cursor: pointer; font-size: 1.4rem; }
    }

    .dashboard-table-column:last-of-type {
        p {
            text-decoration: none;
            cursor: unset;
        }
    }

    .row {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    }

    .row:nth-child(even) {
        background-color: #e4e4e4;
    }
}

#app-special {
    max-width: 700px;
    margin: 0 auto;

    .form-checkbox-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
        margin: 25px auto;
        margin-top: 0;

        div {
            width: 200px;
            display: flex;
            justify-content: flex-start;
            height: 30px;
        }
    }

}

.special-form {
    text-align: left;
    width: 100%;

    .form-row {
        justify-content: flex-start;
    }

    .css-2b097c-container {
        width: 95%;
    }
}

// .specials-services-items {
//     width: 100%;
//     display: flex;
//     flex-wrap: wrap;
//     max-width: 500px;
//     margin: 25px auto;
// }

.specials-service-item, .specials-item-item {
    width: 50%;

    input {
        height: 20px !important;
        margin-right: 10px;
    }
}


.form-row.discount-type p {
    width: 100%;
    font-size: 1.4rem;
    color: $dark-green;
    text-align: center;
    font-weight: bold;
}

.new-applicable-services, .new-applicable-tailoring-items, .new-applicable-dryclean-items {
    padding: 10px;
}
$body-font: 'Rubik', 'Raleway', sans-serif;
$header-font: 'Gotham Black', 'Montserrat', sans-serif;
$accent-font: 'Fredoka One', 'Rubik', 'Gotham Black','Montserrat', sans-serif;

$green: #8CB825;
$med-green: #608F00;
$dark-green: #104829;
$dark-red: #c60000;
$orange: #ED8F25;
$yellow: #F5C732;
$light-gray: #CCCDC7;
$white: #EAEAE8;
$black: black;
$dark-gray: #cccccc;

$small-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$medium-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

.bg-green { 
    background-color: $green;
    color: white;
}

.bg-orange {
    background-color: $orange;
    color: white;
}

.bg-med-green {
    background-color: $med-green;
    color: white;
}

.bg-yellow {
    background-color: $yellow;
    color: $black;
}
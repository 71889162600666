@import "../../global/styles/global.scss";

#specials {
    padding-top: 100px;

    .sticker {
        position: relative;
        top: 15%;
        width: 125px;
        height: 125px;
        right: 0;
        margin: 0 auto;
        border: 5px solid white;

        span:first-of-type {
            font-size: 1.8rem;
        }

        span:nth-of-type(2) {
            font-size: 4rem;
        }

        span:nth-of-type(3) {
            font-size: 2rem;
        }
    }

}

#specials-hero {
    text-align: center;
    margin-bottom: 75px;
}

.specials-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-gap: 15px;
    margin-bottom: 100px;

    @include respond-below(md) {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
    }

    @include respond-below(sm) {
        grid-template-columns: repeat(1, minmax(100px, 1fr));
    }
}

.specials-grid-item {
    position: relative;
    text-align: center;

    img {
        height: 100px;
        width: auto;
    }

    .icon-percent {
        height: 75px;
    }

    h1 {
        color: $orange;
        margin-bottom: 0;
        font-size: 3rem;
    }

    h2 {
        color: $med-green;
        margin: 0;
        padding: 0;
    }

    a {
        color: $orange;
        text-transform: uppercase;
        text-decoration: underline;
        font-family: $accent-font;
    }

    @include respond-below(xs) {

        img {
            height: 75px;
        }

        h1 {
            font-size: 3rem;
        }

        h2 {
            font-size: 2rem;
        }
    }
}

.locations {
    background-color: #F2F2E2;
    padding-bottom: 100px;

    .locations-container {
        padding: 10px;
    }


    .locations-heading {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .locations-list {
        column-count: 2;

        p {
            margin: 0;
        }
    }

    @include respond-below(sm) {

        .locations-heading {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        .locations-container {
            column-count: 1;
        }
    }
}

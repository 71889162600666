.content-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 25px;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
}

.image-container {
    position: relative;
    
    img.vertical {
        max-height: 500px;
        width: auto;
        margin: 0 auto;

        @include respond-below(xs) {
            max-height: 300px;
            margin: 25px auto;
        }
    }

    img {
        width: 100%;
    }
}



.stripe-element-container {
    max-width: 300px !important;
    margin: 10px auto !important;
    padding: 5px !important;
    background-color: white;
    border-radius: 5px;
    font-size: 1.6rem;
}

#checkout-step {

    #submit-changes-button {
        text-align: center;
    }

    h4 {
        text-align: center;
    }

    .save-card-container, .sms-updates-container {
        text-align: center;
        display: flex;
        text-align: left;
        font-size: 1.4rem;
        margin-top: 15px;
        input {
            margin-right: 15px;
        }
        label {
            max-width: 200px;
        }
    }

    .customer-information {
        max-width: 300px;
        margin: 25px auto;
        font-family: inherit;

        .form-input {
            margin-bottom: 25px;
        }

        input {
            display: block;
            border: none;
            height: 30px;
            width: 200px;
            font-size: 1.6rem;
            font-family: inherit;
        }

        label {
            display: block;
       
            margin-bottom: 5px;
        }
    
    }
}


.thank-you {
    text-align: center;
}
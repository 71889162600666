#search-form {
    max-width: 1000px;
    margin: 25px auto;

    form {
        display: flex;
    }
    

}

#order-table-header {
    grid-template-columns: 100px 1fr 1fr 100px 100px 100px 250px 300px;
}


#order-table-rows {
    min-height: 80vh;

    p {
        text-decoration: none;
        cursor: unset;
    }

    .row {
        grid-template-columns: 100px 1fr 1fr 100px 100px 100px 250px  300px;
        border-bottom: 1px solid rgb(198, 198, 198);
    }
}

#order-table-header.customer-view {
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
}

#order-table-rows.customer-view {
    .row {
        grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
    }
}

.status-data, .location-data {
    button {
        margin-left: 10px !important;
    }
}

#location-form, #status-form {
    display: flex;
    justify-content: space-between;

    .css-2b097c-container {
        width: 100px;
    }

    .form-input {
        min-width: 220px;
    }


    .submit-button {
        font-size: 1.2rem;
        padding: 5px;
        margin-left: 10px;
    }
}

.status-data, .location-data {

    a {
        cursor: pointer;
        color: $dark-green;
        display: block;
    }

    // height: 20px;
    // margin-bottom: 5px !important;

}

.app-orders-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1250px;
    margin: 25px auto;
    width: 100%;

    .space {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .field {
        margin: 10px;
    }

    input[type="submit"] {
        @include respond-below(xs) {
            width: 200px;
        }
    }

    input[type="text"] {
        width: 185px;
        height: 20px;
        border-radius: 5px;
    }


    .datepicker-field{ 
        margin: 0;
    }

    .css-2b097c-container {
        width: 200px;      
    }

    .input-group {
        display: flex;
    }

    .search-input {
        display: flex;
    }

    .search-input-button {
        margin-top:5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        width: 35px;
        height: 35px;
        color: white;   
        background-color: $med-green;
        box-shadow: none;
        margin-left: 5px;
        margin-right: 10px;
        box-shadow: $small-shadow;
    }

    .submit-button {
        background-color: #545650;
        margin-top: 10px;
        font-size: 1.4rem !important;
    }

    span {
        line-height: 30px;
        font-size: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

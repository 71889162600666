.heading-full {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.heading-half {
  max-width: 600px;
  padding: 0 !important;
  text-align: left !important;
}

.error-banner {
  width: 100%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  p {
    color: $dark-red;
    font-style: italic;
  }
}

#verification {
    min-height: 90vh;
    margin-top: -100px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3, h4 {
        max-width: 500px;
        margin: 0 auto;
        color: $green;
        line-height: 1.5;
    }

    a {
        color: black;
    }
}
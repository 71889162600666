#order-detail {
    min-height: 80vh;
    margin-top: 50px;


    h4 {
        text-align: center;
        font-size: 1.8rem;
        margin-bottom: 10px;
        color: $green;
    }

    h3 {
        text-align: center;
    }

    #order-summary p {
        font-weight: normal;
    }
}

#guest-auth-form {
    p {
        text-align: center;
        font-size: 1.6rem;
    }
}

.cancellation-confirmation {
    font-weight: bold;
    color: $dark-red;
    font-size: 1.8rem;
}

.order-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    h5 {
        color: black !important;
        font-family: $body-font;
        text-align: left !important;
        margin: 0 !important;
        font-size: 1.6rem !important;
    }


    p {
        text-align: left !important;
        margin: 0;
        font-weight: normal !important;
    }

    .address, .pickUp, .delivery {
        width: 200px;
        
    }

    .address {
        width: 50%;
    }

    .contact {
        width: 50%;
    }

    .pickUp {
        width: 50%;
    }

    .delivery {
        width: 50%;

    }
}

.order-detail-status, .order-detail-location {
    display: flex;
    flex-wrap: wrap;

    h4 {
        width: 100px;
        text-align: left !important;
    }

    .table-form-container {
        margin-top: 5px;
        width: 50%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }
}

.order-detail-status-forms {
    border-top: 1px solid $green;
    border-bottom: 1px solid $green;
}


.order-detail-form-container {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: $green;
    color: white;
  


    .flex-container {
        margin-bottom: 15px;

        .label {
            width: 50%;
            font-weight: bold;
        }
    }

    .action-button-container {
        margin-top: 0;

        input {
            background-color: $orange;
        }
    }
}

.service-quote {
    text-align: right;
    position: relative;
    width: 35%;
    min-width: 100px;
}

.inline-form {
    .submit-button {
        font-size: 1.4rem;
        padding: 5px 10px 5px 10px;
    }
}

.inline-form-input {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;

    label {
        
        font-size: 1.4rem;
        line-height: 3rem;
    }

    input {
        min-width: 100px;
    }

}

.order-additional-items {
    display: flex;
    justify-content: space-between;
}

.additional-items-total {
    text-align: right;
    width: 35%;
    min-width: 100px;
}

.order-gratuity {
    margin-bottom: 10px;
    font-style: italic;
}


.order-update-notes {
    color: $med-green !important;
    font-weight: bold !important;

}

.reschedule-button {
    background-color: $green !important;
    color: white !important;
    padding: 5px 15px 5px 15px !important;
    border-radius: 1000px !important;
    font-size: 1.8rem !important;
    margin-bottom: 100px !important;
    box-shadow: $small-shadow !important;
}

#reschedule-form {
    padding-bottom: 25px;

    input {
        font-size: 1.6rem !important;
    }
}

.order-details {
    .address, .pickUp, .delivery {
        margin-bottom: 25px;
    }
}

.reauth-text {
    margin-bottom: 15px;
    color: white;
}

.reauth-module {
    background-color: $green;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 10px;
    box-shadow: $small-shadow;
}

#reauthorize-form {

   
    .action-button-container {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    input[type="submit"] {
        background-color: $med-green;
    }

    .css-26l3qy-menu {
        color: black !important;
    }
}

.capture-payment-form, .refund-form {
    span {
        width: 10px;
        margin-right: 5px;
    }

    textarea {
        margin-left: 0;
    }

  
}
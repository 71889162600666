#privacy {
  padding: 25px;
  margin-bottom: 75px;

  .content {
    max-width: 800px;
    margin: 0 auto;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 1.6rem;
  }

  p,
  li {
    font-size: 1.4rem;
  }
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // min-height: 50vh;

  // a {
  //     font-size: 2rem;
  //     text-decoration: none;
  //     color: $med-green;
  // }
}

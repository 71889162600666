@import "../../styles/global.scss";

#header {
  position: relative;
  min-height: 100px;
  z-index: 100;

  .hamburger {
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 15px;
    height: 40px;
    width: 40px;
    display: none;

    @include respond-below(md) {
      display: block;
    }
  }

  a {
    font-family: "Gotham Book", "Gotham Black", sans-serif;
    text-decoration: none;
    margin: 15px;
    display: inline-block;
  }

  #login-link {
    background-color: $med-green;
    border: 3px solid white;
    font-weight: bold;
    padding: 5px 10px 5px 10px;
    color: white;
    border-radius: 1000px;
    min-width: 100px;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    font-size: 1.8rem;

    text-align: center;

    @include respond-below(xl) {
      font-size: 1.6rem;
    }
  }

  .top-bar {
    position: relative;
    height: 20px;
    background-color: $green;
    color: white;
    padding: 5px;

    &.promo {
      font-size: 20px;
      background-color: #f5c732;
      color: black;
      text-align: center;
      font-weight: bold;
      padding: 15px;
    }

    a {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      cursor: pointer;
      font-family: "Gotham Bold", "Gotham Book", sans-serif;
      color: white;
    }

    a.active {
      font-weight: bold;
    }

    p {
      font-size: 1.6rem;
    }

    span {
      margin: 0 15px 0 0;
    }

    .left {
      position: absolute;
      top: 0;
      padding: 5px;
      left: 25px;
      height: 20px;
      font-family: $accent-font;
      font-size: 1.6rem;
      line-height: 20px;
    }

    .top-bar-nav {
      position: absolute;
      top: 0;
      padding: 5px;
      right: 15px;
      height: 20px;
      font-family: $accent-font;
      font-size: 1.6rem;
      line-height: 20px;
    }
  }

  .header-logo {
    position: absolute;
    top: 35px;
    left: 25px;
    width: 100px;
  }

  .header-nav {
    width: 90%;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 10;

    a {
      padding: 5px;
      color: $black;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a.active {
      background-color: $green;
      font-weight: bold;
      padding: 5px 10px 5px 10px;
      color: white;
      border-radius: 1000px;
    }

    @include respond-below(md) {
      display: none;
    }
  }

  img {
    width: 100%;
  }

  @include respond-below(sm) {
    .header-logo {
      top: 40px;
      width: 100px;
    }

    .header-nav {
      display: none;
    }

    .top-bar-nav {
      display: none;
    }

    .top-bar {
      height: unset;
      padding-bottom: 0;

      .left {
        position: relative;
        top: unset;
        left: 0;
        height: unset;
        font-size: 1.2rem;
        padding: 0;
      }
    }
  }

  @include respond-below(xs) {
    .header-logo {
      width: 75px;
      top: 50px;
    }

    .top-bar > .left {
      display: flex;

      span {
        width: 50%;
      }

      a {
        margin-right: 0;
        width: 50%;
        text-align: right;
      }
    }
  }
}

#mobile-menu {
  height: 100vh;
  position: absolute;
  width: 100%;
  background-color: $green;
  z-index: 1000;

  .x-icon {
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 15px;
    height: 40px;
    width: 40px;
    display: none;

    @include respond-below(md) {
      display: block;
    }
  }

  .mobile-menu-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 75px;
    width: 75px;
  }

  .mobile-menu-nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 75px;

    a {
      margin: 0;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    p {
      color: white;
      font-size: 2rem;
      padding-top: 5px;
      padding-bottom: 5px;
      transition: all 0.2s ease;
    }

    p:hover {
      background-color: white;
      color: $green;
    }
  }
}

.container-fixed {
  position: fixed;
  width: 100%;
}

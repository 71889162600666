#order-summary.full {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
}

#order-summary {
    background-color: white;
    border-radius: 5px;
    width: 300px;
    padding: 5px 10px 5px 10px;
    margin: 15px;

    h4 {
        margin: 5px;
        text-align: center;
    }

    .order-details {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        margin-bottom: 50px;
    }

    @include respond-below(sm) {
        width: 100%;
    }
   
    .order-label {
        cursor: pointer;
    }

    a {
        text-decoration: none;
        color: black;
    }

    a:visited {
        color: $black;
    }

    p {
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        color: $black;
    }

    ul {
        padding: 0;
        list-style-type: none;
    }
    
    li {
        margin-bottom: 10px;
    }

    button {
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
        color: $black;
        font-size: 1.2rem;
        padding: 0;
        margin: 0;
        margin-right: 5px;
        font-family: $body-font;
        font-weight: bold;
        text-transform: none;
        cursor: pointer;
    }

    .delete-button, .edit-button {
        color: white;
        box-shadow: $medium-shadow;
        border-radius: 2000px;
        border: 3px solid white;
        padding: 8px 12px 8px 12px;
        text-transform: uppercase; 
        font-family: $accent-font;
        font-weight: normal;
        font-size: 1.8rem;
        letter-spacing: 1px;
        cursor: pointer; 
        -webkit-appearance: none;
        background-color: $dark-red;

        @include respond-below(sm) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .delete-button:focus {
        color: white;
        background-color: darken($dark-red, 5%);
    }

    .edit-button {
        background-color: gray;
    }

    .edit-button:focus {
        color: white; 
        background-color: darken(gray, 5%);
    }

    button:focus {
        color: black;
        outline: none;
    }

    .order-item {
        width: 100%;
    }

    .item-name {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .item-details {
        margin-left: 25px;
        font-weight: normal;

        span {
            margin-left: 2px;
            margin-right: 2px;

            @include respond-below(xs) {
                font-size: 1.2rem;
            }
        }


        a {
            @include respond-below(xs) {
                font-size: 1.2rem;
            }
        }

        span:first-of-type {
            margin-right: 5px;
        }

        p {
            font-weight: normal;
            margin: 0;
            text-align: left;

            @include respond-below(xs) {
                font-size: 1.2rem;
            }
        }
    }

    .order-total {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        text-transform: uppercase;
        border-top: 1px solid black;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .order-surcharge {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
        font-style: italic;
    }

    .promo-code-form {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        background-color: #eaeaea;
        padding: 5px;
        padding-bottom: 10px;

        p {
            width: 100%;
        }

        label {
            line-height: 2;
        }

        input {
            margin-left: 10px;
            font-family: inherit;
        }

        input[type="submit"] {
            background-color: $green;
            color: white;
            width: 50px;
        }

    

    }


    .gratuity-form {
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-end;

        background-color: white;
        padding: 5px;
        padding-bottom: 10px;

        label {
            line-height: 2;
        }

        input {
            margin-left: 10px;
            font-family: inherit;
        }

        input[type="submit"] {
            background-color: $green;
            color: white;
            width: 50px;

        }

    }
}
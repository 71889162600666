@import "../../styles/global.scss";

#footer {
    position: relative;
    min-height: 300px;
    background-color: $orange;   

    @include respond-below(xs) {
        min-height: 400px;
    }
}


.footer-logo { 
    position: relative;
    top: -50px;
    z-index: 10;
    margin: 0 auto;
    background-color: white;
    border-radius: 1000px;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $medium-shadow;

    img {
        width: 65%;
    }

    @include respond-below(sm) {
        width: 150px;
        height: 150px;
    }

    
}

.footer-text {
    display: flex;
    position: absolute;
    top: 25px;
    justify-content: center;
    width: calc(100% - 30px);
    padding: 15px;

    @include respond-below(xs) {
        flex-direction: column;
        align-items: center;
        top: 100px;
    }
}

.footer-left {
    color: white;
    margin-right: 100px;
    font-size: 2rem;
    font-weight: bold;

    @include respond-below(xs) {
        margin-right: unset;
    }

}

.footer-right {
    color: white;
    margin-left: 200px;

    .phone {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 2rem;
        span {
            font-weight: normal;
        }
    }

    @include respond-below(xs) {
        margin-left: unset;
        margin-top: 25px;
    }
}

#footer-menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
        margin: 15px;
        text-decoration: none;
        color: white;
    }

    a.active {
        font-weight: bold;
    }

    @include respond-below(sm) {
    margin-top: 125px;
       a, p {
           font-size: 1.4rem;
           text-align: center;
       }

       p {
           margin: 0;
       }
    }
}

.footer-copyright {
    color: white;
    font-size: 1.2rem;
    text-align: right;
    padding: 10px;
}
.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 700px;
    margin: 0 auto;
    
    .service-block {
        cursor: pointer;
        height: 125px;
        width: 175px;
        text-align: center;
        box-shadow: $small-shadow;
        margin: 15px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: $green;
        text-decoration: none;
    
        img {
            display: block;
        }
    
        label {
            color: white;
            margin-top: 10px;
        }
    }
}

#wash-fold-step {
    .form-line {
        margin-bottom: 25px;
    }

    span {
        font-size: 1.4rem;
    }

    .form-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        input {
            margin-right: 10px;
        }
    }

    .products-item {
        width: 50%;
        display: flex;
        margin-bottom: 10px;
        font-size: 1.4rem;

        @include respond-below(xs) {
            width: 100%;
        }
    }
}


.tailoring-repeater-module {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .repeater-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 15px;
        max-width: 800px;
        margin: 0 auto;

        @include respond-below(xs) {
            max-width: 275px;
        }

        .label {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            margin-right: 10px;
        }

        .select {
            min-width: 150px;
        }

    
        .item-name {
            width: 45%;
        }


        .item-instructions {
            width: 100%;

            textarea {
                height: 150px;
                border-radius: 5px;
                font-size: 1.6rem;
            }
        }

        .cancel {
            width: 45%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .item-options {
            width: 100%;
            padding: 5px;
            margin-top: 25px;
        }


        .form-checkbox-group {
            width: 350px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

       

            div {
                width: 100px;
                display: flex;
                justify-content: space-between;
                margin-right: 25px;
                margin-bottom: 10px;
            }
        }


        @include respond-below(sm) {

            .select {
                min-width: 125px;
            }

            .item-options {
                width: 100%;
            }

            .item-name {
                width: 100%;
            }

            .item-instructions {
                width: 100%;
            }

            .cancel {
                width: 100%;
                justify-content: flex-end;
            }
        }
    }

    .cancel {
        width: 75px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            background-color: transparent;
            box-shadow: none;
            border: none;

            svg {
                color: black;
                height: 20px;
                width: auto;
            }
        }

       @include respond-below(xs) {
           width: 100%;
           justify-content: flex-end;

           button {
               height: 30px;
               width: 30px;
           }

           svg {
               height: 15px;
               width: 10px;
           }
       }
    }

}

.dryclean-repeater-module {
    .repeater-item {
        @include respond-below(sm) {

            .select {
                min-width: 125px;
            }

            .item-name {
                width: 130px;
            }

            .item-quantity {
                width: 130px;
            }

            .item-options {
                width: 130px;
            }
        }
    }
}

.dryclean-repeater-module, .washpress-repeater-module, .comforter-repeater-module {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .repeater-item {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .label {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            margin-right: 10px;
        }

        .select {
            min-width: 150px;
            margin: 5px;
        }

        .item-quantity {
            display: flex;
        }


        @include respond-below(sm) {

            .select {
                min-width: 125px;
            }

            .item-name {
                width: 130px;
            }

            .item-quantity {
                width: 130px;
            }
        }
    }

    .cancel {
        width: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        button {
            background-color: transparent;
            box-shadow: none;
            border: none;

            svg {
                color: black;
                height: 20px;
                width: auto;
            }
        }

       @include respond-below(xs) {
           width: 100%;
           justify-content: flex-end;

           button {
               height: 30px;
               width: 30px;
           }

           svg {
               height: 15px;
               width: 10px;
           }
       }
    }
}


.repeater-module:nth-child(even) {
    background-color: #EAEAE8;
}
@import "../../styles/global.scss";

.banner-footer {
    position: relative;
    min-height: 500px;
    background-size: cover;
    background-position: top;

    .sticker {
        z-index: 10;
        top: 45%;
        right: 10%;
        width: 100px;
        height: 100px;
        border: 5px solid white;

        span:nth-of-type(1) {
            font-size: 1.8rem;
        }
    
        span:nth-of-type(2) {
            font-size: 4rem;
        }
    
        span:nth-of-type(3) {
            font-size: 1.6rem;
        }
    }

    .content {
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: $green;
        text-align: center;
        color: white;
        min-height: 200px;
    }

    h3 {
        margin: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @include respond-below(sm) {
        .sticker {
            top: 30%;
        }
    }

    @include respond-below(xs) {
        .sticker {
            top: 10%;
        }
    }
}

.banner-zip-form-container {
    margin-bottom: 100px;
    text-align: center;

    #zip-form {
        justify-content: center;
    }

 
    input {
        font-size: 3rem;
        color: white;
        text-align: center;
    }

    input::placeholder {
        color: white;
        font-size: 3rem;
        
    }

}